import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleCard from "../components/article-card"
import ArticleTagList from "../components/article-tag-list"
import * as styles from "./article-index-by-tag.module.scss"

export default function ArticleIndex({ data, location }) {
    var articleTag = data.wagtailbase.articleTag
    var articles = articleTag.taggedArticles
    // sort by article date
    articles.sort(function(a, b) {
        if(a.contentObject.date < b.contentObject.date) return 1;
        if(a.contentObject.date > b.contentObject.date) return -1;
        return 0;
    });

    return (
        <Layout location={location}>
            <SEO
                title={articleTag.name + `の記事`}
            />
            <article>
                <header className={styles.articleListHeader}>
                    <h1>
                        {articleTag.name}の記事
                    </h1>
                    <section className={styles.articleTagsContainer}>
                        <ArticleTagList selectedTag={articleTag} />
                    </section>
                </header>
                <section className={styles.articleCardsContainer}>
                    {articles.map(article => {
                        return <ArticleCard article={article.contentObject} key={article.contentObject.slug} />
                    })}
                </section>
            </article>
        </Layout>
    )
}


export const query = graphql`
    query($slug: String!) {
        wagtailbase {
            articleTag(slug: $slug) {
              name
              slug
              taggedArticles {
                contentObject {
                  slug
                  title
                  overview
                  date
                  bannerImage {
                    url
                    thumbnailWebp: rendition(fill:"800x420", format: "webp") {
                      url
                    }
                    thumbnailJpeg: rendition(fill:"800x420", format: "jpeg") {
                      url
                    }
                  }
                }
              }
            }
        }
    }
`
